
import { capitalize, defineComponent } from 'vue';
import text, { acronym, ucwords } from '@/utils/text';
import Avatar from '@/components/avatars/Avatar.vue';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';

export default defineComponent({
  props: {
    currentTenantReview: {
      default: () => {
        return {} as any;
      },
    },
  },
  components: { BusinessLogo, Avatar },
  computed: {
    hasEmail() {
      return this.currentTenantReview.user.email;
    },
    hasMobileNumber() {
      return this.currentTenantReview.user.mobile_number;
    },
    address() {
      const addressLine =
        this.currentTenantReview?.user?.business_address_line !== ''
          ? this.currentTenantReview?.user?.business_address_line + ', '
          : '';
      const city =
        this.currentTenantReview?.user?.business_city !== ''
          ? this.currentTenantReview?.user?.business_city + ', '
          : '';
      const state =
        this.currentTenantReview?.user?.business_state !== ''
          ? this.currentTenantReview?.user?.business_state + ', '
          : '';
      const postCode =
        this.currentTenantReview?.user?.business_post_code !== ''
          ? this.currentTenantReview?.user?.business_post_code + ', '
          : '';

      return this.currentTenantReview.user.roles[0].role == 'Admin'
        ? null
        : `${addressLine} ${city} ${state} ${postCode}`;
    },
    businessLogoSrc() {
      return (this.currentTenantReview as any)?.user?.business_logo_src;
    },
    agentAvatarSrc() {
      return (this.currentTenantReview as any)?.user?.avatar_src;
    },
    agentName() {
      const firstName = (this.currentTenantReview as any)?.user?.first_name;
      const lastName = (this.currentTenantReview as any)?.user?.last_name;

      return ucwords(`${firstName} ${lastName}`);
    },
    agentAcronym() {
      const firstName = (this.currentTenantReview as any)?.user?.first_name;
      const lastName = (this.currentTenantReview as any)?.user?.last_name;

      if (firstName === '' && lastName === '')
        return acronym((this.currentTenantReview as any)?.user?.email);

      return acronym(`${firstName} ${lastName}`);
    },
    businessName() {
      return (this.currentTenantReview as any)?.user?.business_name;
    },
    businessBackgroundColor() {
      return (this.currentTenantReview as any)?.user?.business_color;
    },
    businessTextColor() {
      return (this.currentTenantReview as any)?.user?.business_text_color;
    },
    formattedAbn() {
      return this.currentTenantReview?.user?.abn
        ? text.formatABN(this.currentTenantReview?.user?.abn)
        : null;
    },
    formattedAcn() {
      return this.currentTenantReview?.user?.acn
        ? text.formatACN(this.currentTenantReview?.user?.acn)
        : null;
    },
    businessNumber() {
      return this.currentTenantReview.user.business_phone_number.replace(
        /\s/g,
        ''
      );
    },
  },
});
