
import { defineComponent } from 'vue';
import { numberFormat } from '@/utils/text';
import timeUtil from '@/utils/time';
import _ from 'lodash';
import StreetViewImage from '@/views/new-design/pages/ViewTenantReview/StreetViewImage.vue';
import { TenantReview } from '@/models/TenantReviewModel';
import { mapActions } from 'vuex';
import { Actions } from '@/store/enums/TenantReviewEnums';
import useBreakpoints from 'vue-next-breakpoints';

export default defineComponent({
  name: 'rental-review-list-section',
  emits: ['selectRentalHistory', 'expandStreetView', 'viewReport'],
  components: { StreetViewImage },
  data: () => {
    return {
      loadingList: true,
      streetViewImage: '',
    };
  },
  props: {
    review: {
      default: () => {
        return {} as unknown as TenantReview;
      },
    },
    currentTenantReview: {
      type: Object,
      default: () => {
        return {} as unknown as TenantReview;
      },
    },
    tenantReviews: {
      type: Array,
      default: () => {
        return [] as unknown as Array<TenantReview>;
      },
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
    breakpoints(){
      return useBreakpoints({
        mobile: 600, // max-width: 600px
        desktop: [601], // min-width: 601px
      });
    }
  },
  methods: {
    isCurrent(review) {
      return (this.currentTenantReview as any)?.id == review.id;
    },
    dateFormat(date, format='aus') {
      return timeUtil.dateFormat(date, format);
    },
    toDecimal(number) {
      return numberFormat(number, 1);
    },
    getAddress(review) {
      return `${review?.address_line}, ${review?.city}, ${review?.post_code}, ${review?.state}`;
    },
    getDates(review) {
      if ((this.breakpoints as any).mobile.matches) {
        return `${this.dateFormat(review.move_in_date, 'short')} - ${this.dateFormat(
          review.move_out_date, 'short'
        )}`;
      }

      return `${this.dateFormat(review.move_in_date)} - ${this.dateFormat(
        review.move_out_date
      )}`;
    },
    hasCoordinates(review) {
      return (
        parseFloat(review.latitude) != 0 && parseFloat(review.longitude) != 0
      );
    },
    viewStreetView() {
      this.$emit('expandStreetView', {
        lat: parseFloat((this.review as any)?.latitude),
        lng: parseFloat((this.review as any)?.longitude),
      });
    },
    viewReport() {
      this.$emit('viewReport');
    },
    getRate(rate) {
      return parseFloat(this.toDecimal(rate)) > 0
        ? this.toDecimal(rate)
        : 'Not Reviewed';
    },
    getRateManual(review) {
      console.log(review);
      let answers = review?.review_answers?.filter(
        (item) => item.type == 'rating'
      );

      let total = answers.reduce((previousValue, currentValue) => {
        return previousValue + parseFloat(currentValue.answer);
      }, 0);

      let average = total / answers.length;

      return average > 0 ? average.toFixed(1) : 'Not Reviewed';
    },
    hasRate(rate) {
      return parseFloat(this.toDecimal(rate)) > 0;
    },
    renderPhoneNumber(phoneNo) {
      return `tel:${phoneNo.replace(/[^0-9]/g, '')}`;
    },
  },
  // async created() {
  //   if (this.review) {
  //     this.getStreeViewImage();
  //   }
  // },
});
