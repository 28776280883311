
import { defineComponent } from 'vue';
import _ from 'lodash';
import HistoryItem from '@/views/new-design/pages/ViewTenantReview/HistoryItem.vue';
import StreetViewModal from '@/views/new-design/pages/ViewTenantReview/StreetViewModal.vue';
import { TenantReview } from '@/models/TenantReviewModel';

export default defineComponent({
  name: 'rental-review-list-section',
  emits: ['selectRentalHistory'],
  components: {
    HistoryItem,
    StreetViewModal,
  },
  data() {
    return {
      loadingList: true,
      selectedCoordinates: {} as any,
      showingStreetView: false,
    };
  },
  props: {
    currentTenantReview: {
      type: Object,
      default: () => {
        return {} as unknown as TenantReview;
      },
    },
    tenantReviews: {
      type: Array,
      default: () => {
        return [] as unknown as Array<TenantReview>;
      },
    },
  },
  watch: {
    selectedCoordinates: {
      handler() {
        this.$nextTick(() => {
          (this.$refs.streetviewmodal as any).initializeStreetView();
        });
      },
      deep: true,
    },
  },
  methods: {
    expandStreetView(coordinates: any) {
      this.selectedCoordinates = coordinates;
      this.showingStreetView = true;
    },
    selectRentalHistory(referenceNo) {
      this.$emit('selectRentalHistory', referenceNo);
    },
  },
});
