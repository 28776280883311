
import { numberFormat } from '@/utils/text';
import { money } from '@/utils/currency';
import timeUtil from '@/utils/time';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import EmailReportModal from '@/views/new-design/pages/ViewTenantReview/EmailReport.vue';
import toasts from '@/utils/toasts';
import PDFGenerator from '@/views/new-design/pages/ViewTenantReview/PDFGenerator.vue';
import LedgerReport from '@/views/new-design/pages/ViewTenantReview/Report/Ledger.vue';
import NoReviewSubmitted from '@/views/new-design/pages/ViewTenantReview/NoReviewSubmitted.vue';
import {
  Getters as RQGetters,
  Actions as RQActions,
} from '@/store/enums/ReviewQuestionsEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { BadgeColors } from '@/models/ReviewAnswerModel';

const badgeColor = BadgeColors

export default defineComponent({
  name: 'rental-review-details-section',
  emits: ['editReview'],
  props: {
    tenantReviews: {
      type: Array,
      default: () => undefined,
    },
    currentTenantReview: {
      type: Object,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    reviewDetailLoading: {
      type: Boolean,
      required: false,
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
  },
  components: {
    EmailReportModal,
    PDFGenerator,
    LedgerReport,
    NoReviewSubmitted,
  },
  data: () => ({
    downloading: false,
    sendReport: false,
    sendingReport: false,
    generatingReport: false,
    downloadingReport: false,
    pdfFile: '',
    googleMapImage: '',
  }),
  computed: {
    ...mapGetters({
      actionError: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      reviewQuestions: RQGetters.GET_REVIEW_QUESTIONS,
      authUser: AuthGetters.GET_AUTH_USER,
    }),
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
    address() {
      return `${(this.currentTenantReview as any)?.address_line}, ${
        (this.currentTenantReview as any)?.city
      }, ${(this.currentTenantReview as any)?.post_code}, ${
        (this.currentTenantReview as any)?.state
      }`;
    },
    googleStaticMapImageSrc() {
      const src = (this.currentTenantReview as any)?.map_static_src;
      return src != '' ? src : require('@/assets/new-design/images/map.png');
    },
    tenantRole() {
      // return this.currentTenant.roles[0];
      return 'Tenant';
    },

    reviewsCount() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.filter((item) => {
            return (item as any).is_published === 'yes' && (item as any).is_not_reviewed === 'no';
          }).length
        : 0;
    },
    isMoveDateGreaterThanCreatedDate() {
      const move_out_date = new Date(this.currentTenantReview?.move_out_date);
      const date_now = new Date();

      return move_out_date > date_now ? true : false;
    },
    isEditableByUser() {
      return (
        this.currentTenantReview?.user.business_id == this.authUser?.business_id
      );
    },
    isEditable() {
      const moveOutDate = new Date(this.currentTenantReview?.move_out_date);
      const added2Months = new Date(
        moveOutDate.setMonth(moveOutDate.getMonth() + 2)
      );
      const currentDate = new Date();

      return currentDate < added2Months;
    },
    rentPerWeek() {
      return money.format(this.currentTenantReview?.rent_per_week);
    },
  },
  methods: {
    ...mapActions({
      sendToEmails: Actions.SEND_TENANT_REVIEW_TO_EMAILS,
      getReviewQuestions: RQActions.FETCH_REVIEW_QUESTION,
      updateTenantReview: Actions.UPDATE_TENANT_REVIEW,
      publishTenantReview: Actions.PUBLISH_TENANT_REVIEW,
    }),
    getColor(answer = '' as string) {
      return typeof answer !== 'undefined' && answer !== null
        ? badgeColor[answer.toLowerCase()]
        : '';
    },
    dateFormat(date) {
      return timeUtil.dateFormat(date);
    },
    numberWithCommas(x: any) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatAverageRating(rating) {
      return numberFormat(rating);
    },
    async download(type) {
      if (type == 'ledger') {
        this.downloadLedger();
      } else if (type == 'report') {
        this.downloadingReport = true;
        await this.generateReport();
      }
    },

    async generateReport() {
      await (this.$refs.pdfReport as any).generatePDf();
    },

    async handleSendReport() {
      this.sendReport = true;
    },

    downloadLedger() {
      if (this.downloading) return;
      this.downloading = true;

      // for indicator purposes only
      setTimeout(() => {
        this.downloading = false;
      }, 2000);

      const fileURL = this.currentTenantReview?.tenant_legder_src;
      const fileLink = document.createElement('a');

      fileLink.download = `${this.currentTenantReview?.reference_no}.pdf`;
      fileLink.href = `${fileURL}?download=true`;
      fileLink.target = '_blank';

      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },

    async emailReport(emails) {
      const payload = {
        refNo: this.currentTenantReview?.reference_no,
        emails: Object.values(emails),
      };

      this.sendingReport = true;
      await this.sendToEmails(payload)
        .then(() => {
          toasts.success('Tenant Review Successfully Sent');
          (this.$refs.emailReport as any).clear();
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.sendingReport = false;
          this.sendReport = false;
        });
    },
    handleEditReview() {
      if(!this.isEditableByUser || !this.isEditable){
        return false;
      }

      this.$emit('editReview');
    },
  },
  mounted() {
    this.getReviewQuestions();
  },
});
