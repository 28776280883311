
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import StreetView from '@/views/new-design/components/GoogleMapStreetView.vue';

export default defineComponent({
  props: {
    coordinates: {
      default: () => {
        return { lat: Number, lng: Number } as any;
      },
    },
    loading: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: false },
  },
  components: { Modal, StreetView },
  watch: {
    // dialog(value) {
    //   if (value) {
    // 		if(this.coordinates.lat != '' && this.coordinates.lng != ''){
    // 			this.initializeStreetView()
    // 		}
    //   }
    // },
  },
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    hasCoordinates() {
      return this.coordinates?.lat && this.coordinates?.lng;
    },
  },
  emits: ['submit', 'update:modelValue'],
  methods: {
    initializeStreetView() {
      (this.$refs.streetview as any).initialStreetView();
    },
  },
});
