
import { defineComponent } from 'vue';

import MultipleFileUpload from '@/components/forms/file-upload/MultipleFileUpload.vue';
import toasts from '@/utils/toasts';
import { mapActions, mapGetters } from 'vuex';

import { Actions, Getters } from '@/store/enums/TenantReviewEnums';
import Modal from '@/components/modals/Modal.vue';

export default defineComponent({
  name: 'add-support-files-modal',
  props: {
    max: {
      type: Number,
      default: 1,
    },
    filesUploadLoad: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modal,
    UploadSupportDocuments: MultipleFileUpload,
  },
  data: () => ({
    modelValue: { type: Boolean, default: false },
    file: '',
    loadingUpload: false,
    supportFiles: [] as Array<File>,
  }),
  emits: ['filesUploaded', 'update:modelValue'],
  computed: {
    ...mapGetters({
      currentTenantReview: Getters.GET_CURRENT_TENANT_REVIEW,
    }),
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    ...mapActions({
      uploadSupportDocuments: Actions.UPLOAD_TENANT_REVIEW_SUPPORT_DOCUMENTS,
    }),
    handleChangeFileInput(file) {
      let valid = this.validateSupportFile(file);
      console.log('file', file);
      console.log('valid', valid);
      if (valid) {
        this.supportFiles.push(file.raw);
      }
    },
    handleRemoveFile(file) {
      const fileObject: any = file;
      const fileIndex = this.supportFiles.indexOf(fileObject);

      this.supportFiles.splice(fileIndex, 1);
    },
    validateSupportFile(file) {
      const acceptedFiles = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats',
        'officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats',
        'officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];

      if (!acceptedFiles.includes(file.raw.type)) {
        toasts.error({}, 'The invalid file type.');
        (this.$refs.addSupportDocuments as any).handleRemoveFile(file.raw);
        return false;
      }

      if (file.raw.size / 1024 / 1024 > 5) {
        toasts.error({}, 'File size can not exceed 5MB!');
        (this.$refs.addSupportDocuments as any).handleRemoveFile(file.raw);
        return false;
      }

      return true;
    },
    async handleUploadFiles() {
      if (this.loadingUpload) {
        return;
      }
      this.loadingUpload = true;

      if (!this.supportFiles.length) {
        toasts.error({}, 'Please select files to upload!');
        this.loadingUpload = false;
        return false;
      }

      await this.supportFiles.forEach(async (file) => {
        console.log('support_files', {
          file: file,
          refNo: await this.currentTenantReview.reference_no,
        });

        const params = {
          file: file,
          refNo: await this.currentTenantReview.reference_no,
        };

        return await this.uploadSupportDocuments(params);
      });

      setTimeout(() => {
        toasts.success('Additional files successfully uploaded!');
      }, 2000);

      setTimeout(() => {
        this.loadingUpload = false;
        this.$emit('filesUploaded');
      }, 2000);
    },
  },
});
