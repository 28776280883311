
import { defineComponent } from 'vue';
import router from '@/router';
import timeUtil from '@/utils/time';
import maskUtil from '@/utils/mask';
import { Tenant } from '@/models/TenantModel';
import { capitalize, numberFormat, acronym } from '@/utils/text';
import Avatar from '@/components/avatars/Avatar.vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/enums/UserEnums';
import text from '@/utils/text';

export default defineComponent({
  props: {
    tenantReviews: {
      type: Array,
      default: () => undefined,
    },
    tenant: {
      type: Object,
      default: () => {
        return {} as unknown as Tenant;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
  },
  components: {
    Avatar,
  },
  data: () => ({
    currentTenant: {} as unknown as Tenant,
  }),
  methods: {
    submitTenantReference(id) {
      router.push({
        name: 'submit-tenant-review',
        query: {
          tenant: id,
          step: 2,
        },
      });
    },
    removeSpaces(value) {
      return text.removeSpaces(value);
    },
  },
  computed: {
    ...mapGetters({
      avatarSrc: Getters.GET_AVATAR,
    }),
    rate() {
      return numberFormat(this.averageRating, 1);
    },
    mobileMasking() {
      return maskUtil.mobile.aus;
    },
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
    fullName() {
      const fname = capitalize(`${this.currentTenant.first_name}`);
      const lname = capitalize(`${this.currentTenant.last_name}`);

      return `${fname} ${lname}`;
    },
    tenantAvatarSrc() {
      if (!this.currentTenant.avatar_src) return;

      return this.currentTenant.avatar_src;
    },
    tenantAcronym() {
      const firstName = (this.currentTenant as any)?.first_name;
      const lastName = (this.currentTenant as any)?.last_name;

      if (firstName === '' && lastName === '')
        return acronym((this.currentTenant as any)?.email);

      return acronym(`${firstName} ${lastName}`);
    },
    tenantRole() {
      // return this.currentTenant.roles[0];
      return 'Tenant';
    },
    reviewsCount() {
      return `${(this.tenantReviews as any).filter((item) => {
        return item.is_published === 'yes' && item.is_not_reviewed === 'no';
      }).length} reviews`;
    },
    mobileNumber() {
      const mobileno = this.removeSpaces(this.currentTenant.mobile_number);
      return mobileno;
    },
    formattedMobileNo() {
      return text.formatMobile(this.mobileNumber);
    },
  },
  watch: {
    async tenant(values) {
      this.currentTenant = {
        ...(await values),
        ...{ date_of_birth: timeUtil.dateFormat(await values.date_of_birth) },
      };
    },
  },
});
