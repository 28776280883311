
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    tenant: { default: null },
  },
  methods: {
    submitReview() {
      this.$router.push({
        name: 'submit-tenant-review',
        query: { tenant: (this.tenant as any).id },
      });
    },
  },
});
