
import { defineComponent } from 'vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import Header from '@/views/new-design/pages/ViewTenantReview/Header.vue';
import History from '@/views/new-design/pages/ViewTenantReview/History.vue';
import Report from '@/views/new-design/pages/ViewTenantReview/Report.vue';
import AgentDetails from '@/views/new-design/pages/ViewTenantReview/AgentDetails.vue';
import SupportDocuments from '@/views/new-design/pages/ViewTenantReview/SupportDocuments.vue';
import UpdateReviewModal from '@/components/modals/forms/UpdateReviewModal.vue';

import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions, Mutations } from '@/store/enums/TenantReviewEnums';
import {
  Actions as StoreActions,
  Getters as StoreGetters,
} from '@/store/enums/StoreEnums';
import { TenantReview } from '@/models/TenantReviewModel';
import { Tenant } from '@/models/TenantModel';
import { numberFormat, ucwords } from '@/utils/text';
import store from '@/store';
import {
  Actions as TActions,
  Getters as TGetter,
} from '@/store/enums/TenantEnums';
// import GoogleMap from '@/views/new-design/components/GoogleMap.vue';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import useBreakpoints from 'vue-next-breakpoints';
import Comment from '@/views/new-design/pages/ViewTenantReview/Comment.vue';
import toasts from '@/utils/toasts';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import NoReview from '@/views/new-design/pages/ViewTenantReview/NoReview.vue';

import PDFGenerator from '@/views/new-design/pages/ViewTenantReview/PDFGenerator.vue';
import LedgerReport from '@/views/new-design/pages/ViewTenantReview/Report/Ledger.vue';

import {
  Getters as RQGetters,
  Actions as RQActions,
} from '@/store/enums/ReviewQuestionsEnums';
import {
  Getters as NotificationGetters,
  Actions as NotificationActions,
} from '@/store/enums/NotificationEnums';
import {
  Getters as LogGetters,
  Actions as LogActions,
} from '@/store/enums/LogEnums';

const initialValues = {
  id: '',
  tenant_id: '',
  tenant: {} as Tenant,
  reference_no: '',
} as {} as unknown as TenantReview;

export default defineComponent({
  components: {
    Navigator,
    Header,
    History,
    Report,
    AgentDetails,
    SupportDocuments,
    // GoogleMap,
    BusinessLogo,
    Comment,
    UpdateReviewModal,
    NoReview,
    PDFGenerator,
    LedgerReport,
  },
  data: () => ({
    currentTenantReview: initialValues,
    currentRefNo: '' as string,
    selectedRefNo: '' as string,
    routeRefNo: '' as string | string[],
    tenantId: '' as string | string[],
    loadTenantReview: false,
    loading: false,
    reviewDetailLoading: false,
    averageRating: '0',
    tenant: {} as unknown as Tenant,
    tab: 'report',
    logoData: null,
    breakpoints: {},
    showingHistory: true,
    showingInfo: true,
    windowWidth: window.innerWidth,
    submittingComment: false,
    loadingComments: false,
    isLoadingReview: false,
    updateReview: false,
    formType: 'update',
    formLoading: false,
    pdfFile: '',
    previousNav: {
      to: { name: 'dashboard' },
      text: 'Back to Dashboard'
    }
  }),
  async mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);

    this.loadTenantReview = true;

    this.loading = true;
    this.reviewDetailLoading = true;
    setCurrentPageTitle('Get Tenant Reference');

    await this.fetchCurrentSettings();
    await this.setRouteRefNo();

    await this.loadTenantReviewByRefNo();

    await this.setTenantId();
    await this.setTenant();

    await this.loadTenantReviews();

    await this.setCurrentTenantReview(this.currentRefNo);

    await this.computeAverageRating();

    this.loadTenantReview = false;

    this.breakpoints = useBreakpoints({
      mobile: 800,
      desktop: [801],
    });

    this.setSectionVisibility();

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    ...mapGetters({
      tenantReview: Getters.GET_TENANT_REVIEW,
      tenantReviews: Getters.GET_ALL_TENANT_REVIEWS,
      getTenant: TGetter.GET_TENANT,
      comments: Getters.GET_COMMENTS,
      actionErrors: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      authUser: AuthGetters.GET_AUTH_USER,
      defaultBGColor: StoreGetters.GET_DEFAULT_BACKGROUND_COLOR,
      defaultTextColor: StoreGetters.GET_DEFAULT_TEXT_COLOR,
      reviewQuestions: RQGetters.GET_REVIEW_QUESTIONS,
      settings: NotificationGetters.GET_CURRENT_SETTINGS,
      logError: LogGetters.GET_ACTION_ERROR,
    }),
    tenantRecord() {
      return this.currentTenantReview?.tenant;
    },
    currentAddress() {
      if (this.currentTenantReview) {
        return `${(this.currentTenantReview as any)?.address_line}, ${
          (this.currentTenantReview as any)?.city
        }, ${(this.currentTenantReview as any)?.post_code}, ${
          (this.currentTenantReview as any)?.state
        }`;
      }

      return null;
    },
    headerColor() {
      if (!(this.currentTenantReview as any)?.user?.business_color) {
        return '#4070ea';
      }

      return (
        (this.currentTenantReview as any)?.user?.business_color ??
        process.env.VUE_APP_BACKGROUND_COLOR
      );
    },
    isMobile() {
      return (this.breakpoints as any)?.mobile?.matches;
    },
    isDesktop() {
      return (this.breakpoints as any)?.desktop?.matches;
    },
    currentBusinessName() {
      return ucwords(this.authUser.business_name);
    },
    businessLogoSrc() {
      if (!(this.currentTenantReview as any)?.user?.business_logo) return;

      return (this.currentTenantReview as any)?.user?.business_logo_src ?? '';
    },
    currentCoordinates() {
      const currentReview = this.currentTenantReview as TenantReview;
      const latitude =
        typeof currentReview.latitude !== 'undefined'
          ? currentReview.latitude
          : null;
      const longitude =
        typeof currentReview.longitude !== 'undefined'
          ? currentReview.longitude
          : null;

      return latitude && longitude
        ? {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          }
        : null;
    },
    isPublishableByUser() {
      return (
        (this.currentTenantReview as any)?.user.business_id ==
        this.authUser?.business_id
      );
    },
    isPublishable() {
      const moveOutDate = new Date(this.currentTenantReview?.move_out_date);
      const added2Months = new Date(
        moveOutDate.setMonth(moveOutDate.getMonth() + 2)
      );
      const currentDate = new Date();

      return currentDate < added2Months;
    },
    reviewsCount() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.filter((item) => {
            return item.is_published === 'yes' && item.is_not_reviewed === 'no';
          }).length
        : 0;
    },
    googleStaticMapImageSrc() {
      const src = (this.currentTenantReview as any)?.map_static_src;
      return src != '' ? src : require('@/assets/new-design/images/map.png');
    },
    isDueAuthorizationAccessible() {
      return this.settings.due_authorization === this.authUser.id;
    },
  },
  methods: {
    ...mapActions({
      fetchTenantReviews: Actions.FETCH_ALL_TENANT_REVIEWS,
      fetchTenantReview: Actions.FETCH_TENANT_REVIEW_BY_REFERENCE_NO,
      fetchTenant: TActions.FETCH_TENANT,
      submitComment: Actions.SUBMIT_COMMENT,
      fetchComments: Actions.FETCH_COMMENTS,
      fetchSupportDocuments: Actions.FETCH_TENANT_REVIEW_SUPPORT_DOCUMENTS,
      updateTenantReview: Actions.UPDATE_TENANT_REVIEW,
      publishTenantReview: Actions.PUBLISH_TENANT_REVIEW,
      toggleReportFlag: Actions.TOGGLE_TENANT_REVIEW_FLAG,
      savePDFReport: Actions.SAVE_TENANT_REPORT_PDF,
      retrieveReviewQuestions: RQActions.FETCH_REVIEW_QUESTION,
      fetchCurrentSettings: NotificationActions.FETCH_CURRENT_SETTINGS,
      logRecentViewed: LogActions.LOG_RECENT_VIEWED_HISTORY,
    }),
    async handleLogRecentViewed() {
      const payload = {
        refNo: await this.currentRefNo,
      };
      this.logRecentViewed(payload).then((data) => {
        console.log('Log Recent Viewed', data);
      });
    },
    async handleFetchDocuments(refNo) {
      const payload = {
        refNo: refNo,
      };
      await this.fetchSupportDocuments(payload);
    },
    setSectionVisibility() {
      if ((this.breakpoints as any)?.mobile?.matches) {
        this.showingInfo = false;
      }

      if ((this.breakpoints as any)?.desktop?.matches) {
        this.showingInfo = true;
      }
    },
    back() {
      if ((this.breakpoints as any)?.mobile?.matches) {
        this.showingInfo = false;
      }

      if ((this.breakpoints as any)?.desktop?.matches) {
        this.showingInfo = true;
      }

      this.showingHistory = true;
    },
    setTenantId() {
      this.tenantId =
        typeof this.$route.params.tenantId !== 'undefined'
          ? this.$route.params.tenantId
          : (this.currentTenantReview.tenant_id as string);
    },
    async setCurrentTenantReview(refNo = '') {
      if (refNo) {
        this.currentRefNo = refNo;

        await this.tenantReviews.filter(async (tenantReview: TenantReview) => {
          if (tenantReview.reference_no == refNo) {
            this.loading = false;
            this.reviewDetailLoading = false;
            this.currentTenantReview = tenantReview as TenantReview;
          }
        });

        this.handleLogRecentViewed();

        return store.commit(
          Mutations.SET_CURRENT_TENANT_REVIEW,
          this.currentTenantReview
        );
      }

      if (refNo === '' && this.tenantReviews.length) {
        this.currentTenantReview = (await this
          .tenantReviews[0]) as TenantReview;

        this.currentRefNo = this.currentTenantReview?.reference_no as any;

        store.commit(
          Mutations.SET_CURRENT_TENANT_REVIEW,
          this.currentTenantReview
        );

        this.handleLogRecentViewed();
      }
    },
    setRouteRefNo() {
      this.currentRefNo = (
        typeof this.$route.params.refNo !== 'undefined'
          ? this.$route.params.refNo
          : ''
      ) as string;
    },
    async loadTenantReviewByRefNo() {
      if (!this.currentRefNo) return;

      // fetch tenant reviews by refno
      await this.fetchTenantReview(this.currentRefNo);
    },
    async setTenant() {
      if (this.$route.params.tenantId) {
        await this.fetchTenant(this.tenantId);
        this.tenant = this.getTenant;
      } else {
        this.tenant = this.tenantReview.tenant;
      }
    },
    async loadTenantReviews() {
      this.loadTenantReview = true;
      // fetch tenant reviews by tenant id
      const id = this.$route.params.tenantId ?? this.tenantReview.tenant_id;
      await this.fetchTenantReviews(id).finally(() => {
        this.loadTenantReview = false;
      });
    },
    async handleSelectRentalHistory(values) {
      this.loading = true;
      this.reviewDetailLoading = true;
      this.isLoadingReview = true;
      this.tab = 'report';

      this.currentRefNo = await values.reference_no;
      await this.setCurrentTenantReview(this.currentRefNo);

      setTimeout(async () => {
        // await this.handleFetchDocuments(await this.currentRefNo);

        this.isLoadingReview = false;
        this.loading = false;
        this.reviewDetailLoading = false;
      }, 1000);

      if ((this.breakpoints as any)?.mobile.matches) {
        this.showingHistory = false;
      } else {
        this.showingHistory = true;
      }

      this.showingInfo = true;

      this.$nextTick(() => {
        if ((this.breakpoints as any)?.mobile.matches) {
          (this.$refs?.info as any)?.scrollIntoView();
        }
      });
    },
    computeAverageRating() {
      let sumRating = 0;
      let totalReviews = 0;
      if (
        typeof this.tenantReviews !== 'undefined' &&
        this.tenantReviews.length > 0
      ) {
        this.tenantReviews.map((review: TenantReview) => {
          if (
            review.status === 'published' &&
            review.is_not_reviewed === 'no'
          ) {
            sumRating = sumRating + parseFloat(review.average_rating);
            totalReviews++;
          }
        }, 0);
      }
      const average = totalReviews > 0 ? sumRating / totalReviews : 0;
      this.averageRating = average.toFixed(1);
    },
    onResize() {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth > 576) {
        this.showingInfo = true;
        this.showingHistory = true;
      } else {
        this.showingInfo = false;
        this.showingHistory = true;
      }
    },
    async submitTenantComment(data: any) {
      this.submittingComment = true;
      await this.submitComment({
        ...data,
        ...{ parent_id: null, ref_id: this.currentTenantReview.reference_no },
      })
        .then(() => {
          this.fetchTenantComments();
        })
        .catch(() => {
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        })
        .finally(() => (this.submittingComment = false));
    },
    async fetchTenantComments() {
      if (this.selectedRefNo === this.currentTenantReview.reference_no) return;

      if (this.currentTenantReview) {
        this.loadingComments = true;
        await this.fetchComments(this.currentTenantReview.reference_no).finally(
          () => {
            this.loadingComments = false;
          }
        );
      }
    },
    updateTenantReference() {
      this.formType = 'update';
      this.updateReview = true;
    },
    reviewTenantReference() {
      this.formType = 'review';
      this.updateReview = true;
    },
    confirmPublishAction(reviews) {
      toasts.confirm(
        'Are you sure you want to publish tenant report?',
        ({ isConfirmed }) => {
          if (isConfirmed) {
            this.handlePublishTenantReview(reviews);
          } else {
            this.formLoading = false;
            this.updateReview = false;
          }
        }
      );
    },
    confirmNotReviewing(payload) {
      toasts.confirm(
        "Are you sure you want to leave a 'No Review' for the tenant? \nBy continuing, any selection below will be disregarded and be taken to the next step ",
        ({ isConfirmed }) => {
          if (isConfirmed) {
            this.handleUpdateTenantReview(payload);
          } else {
            this.formLoading = false;
          }
        }
      );
    },
    setTenantReviewNewValue(newVal) {
      const selectedIndex = this.tenantReviews.findIndex(
        (item) => item.reference_no === newVal.reference_no
      );
      this.tenantReviews[selectedIndex] = newVal;

      this.currentTenantReview = newVal;
    },
    async handleUpdateTenantReview(payload) {
      if (this.formLoading) return;
      this.formLoading = true;

      await this.updateTenantReview({
        reviewRefNo: this.currentTenantReview?.reference_no,
        values: payload,
      })
        .then(async (data) => {
          this.setTenantReviewNewValue(data.data);
          toasts.success('Tenant Review has been updated.');

          this.handleGenerateReport();
          this.computeAverageRating();
        })
        .catch((error) => {
          toasts.error({}, 'Something went wrong. Please try again.');
        })
        .finally(() => {
          this.updateReview = false;
          this.formLoading = false;
        });
    },
    async handlePublishTenantReview(reviews) {
      if (this.formLoading) return;
      this.formLoading = true;

      return await this.publishTenantReview({
        reviewRefNo: this.currentTenantReview?.reference_no,
        values: reviews,
      })
        .then(async (data) => {
          this.setTenantReviewNewValue(data.data);
          toasts.success('Tenant Review has been published.');

          this.handleGenerateReport();
          this.computeAverageRating();
        })
        .catch(() => {
          toasts.error({}, 'Something went wrong. Please try again.');
        })
        .finally(() => {
          this.updateReview = false;
          this.formLoading = false;
        });
    },
    handleGenerateReport() {
      (this.$refs.sendPdfReport as any).generatePDf();
    },
    async handleBeforeDownload(pdf) {
      if (this.pdfFile !== pdf) {
        this.pdfFile = pdf;
      }
    },
    handleRetrieveQuestions() {
      if (this.reviewQuestions.length > 0) return;

      return this.retrieveReviewQuestions();
    },
  },
  watch: {
    currentTenantReview: {
      handler(value) {
        store.commit(Mutations.SET_AGENT_AVATAR, '');

        if (value && value.reference_no) {
          this.fetchTenantComments();
          this.handleFetchDocuments(value.reference_no);
        }
      },
      deep: true,
    },
    async pdfFile(value) {
      if (await value) {
        const data = {
          refNo: this.currentTenantReview?.reference_no,
          report: await value,
        };

        this.savePDFReport(data);

        this.pdfFile = '';
      }
    },
  },
  beforeMount() {
    window.removeEventListener('resize', this.onResize);
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
        if(from.name == 'dashboard'){
            vm.previousNav = {
                to: { name: 'dashboard' },
                text: 'Back to Dashboard'
            }
        }else if(from.name == 'search-tenant-report') {
          vm.previousNav = {
              to: { name: 'search-tenant-report' },
              text: 'Back to Search Tenant'
          }
        }
    })
  },
});
