
import { defineComponent } from 'vue';

import Avatar from '@/components/avatars/Avatar.vue';
import { User } from '@/models/UserModel';
import { acronym } from '@/utils/text';

export default defineComponent({
  components: { Avatar },
  props: {
    loadingList: { default: false },
    submitting: { default: false },
    comments: {
      default: () => {
        return [];
      },
    } as any,
    userId: { default: null } as any,
    useForm: { default: true },
  },
  data() {
    return {
      form: {
        comment: '',
      },
    };
  },
  emits: ['submit'],
  methods: {
    formatAcronym(user: User) {
      const firstName = user?.first_name;
      const lastName = user?.last_name;

      if (firstName === '' && lastName === '') return acronym(user?.email);

      return acronym(`${firstName} ${lastName}`);
    },
    submit() {
      this.$emit('submit', this.form);
      this.$nextTick(() => (this.form.comment = ''));
    },
  },
});
