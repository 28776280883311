
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    imageSrc: { default: '' },
  },
  emits: ['view'],
  methods: {
    expandStreetView() {
      this.$emit('view');
    },
  },
});
