
import Modal from '@/components/modals/Modal.vue';
import { defineComponent, ref } from 'vue';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import _ from 'lodash';
import { TenantReview } from '@/models/TenantReviewModel';
import { ReviewQuestion } from '@/models/ReviewQuestionModel';
import { ReviewAnswer } from '@/models/ReviewAnswerModel';

export default defineComponent({
  name: 'update-review-modal',
  props: {
    modelValue: { type: Boolean, default: false },
    refNo: {
      type: String,
      default: null,
    },
    questions: {
      type: Array,
      default: () => {
        return [] as unknown as Array<ReviewQuestion>;
      },
    },
    answers: {
      type: Array,
      default: () => {
        return [] as unknown as Array<ReviewAnswer>;
      },
    },
    type: {
      type: String,
      default: 'update',
    },
    loading: { default: false },
  },
  components: {
    Modal,
  },
  data: () => {
    return {
      v$: useValidate(),
      form: {
        reviewAnswers: [],
      },
      ratingTexts: ['Very bad', 'Disappointed', 'Fair', 'Good', 'Very Good'],
      invalidData: [] as any,
      loadingForm: false,
    };
  },
  emits: ['update:modelValue', 'update', 'publish', 'notreviewing'],
  computed: {
    buttonTitle() {
      return this.type == 'update' ? 'Update' : 'Publish';
    },
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    modalTitle() {
      return this.type == 'update' ? 'Update' : 'Review';
    },
  },
  methods: {
    isFormValid() {
      const vm = this;
      this.invalidData = [];

      _.forEach(this.questions, function (value, key) {
        if (
          (value as any)?.is_required === 'yes' &&
          (!vm.form.reviewAnswers[key] ||
            typeof vm.form.reviewAnswers[key] === 'undefined' ||
            vm.form.reviewAnswers[key] === '')
        ) {
          vm.invalidData.push(key);
        }
      });

      if (this.invalidData.length) {
        return false;
      }

      return true;
    },
    setFormValue() {
      setTimeout(() => {
        _.forEach(this.answers, (value, key) => {
          (this.form as any).reviewAnswers[key] = (value as any)?.answer;
        });
      }, 2000);
    },
    setValues(answer = true) {
      var answers = [] as any;

      _.forEach(this.form.reviewAnswers, (value, key) => {
        const question = this.questions[key] as ReviewQuestion;
        answers.push({
          id: question.id,
          code: question.code,
          type: question.type,
          options: question.options,
          question: question.question,
          answer: answer ? value : null,
          default_answer: question.default_answer,
        });
      });

      return answers;
    },
    notReviewing() {
      const answers = this.setValues(false);
      this.$emit('notreviewing', {
        review_answers: answers,
        flag: 'no_review',
      });
    },
    handleSubmitForm() {
      if (this.loadingForm) return;
      this.loadingForm = true;

      if (!this.isFormValid()) {
        this.loadingForm = false;
        return false;
      }

      const answers = this.setValues();

      setTimeout(() => {
        this.loadingForm = false;
      }, 2000);

      if (this.type == 'update') {
        this.$emit('update', { review_answers: answers });
      } else if (this.type == 'review') {
        this.$emit('publish', { review_answers: answers });
      }
    },
  },
  watch: {
    // async answers(values) {
    //   if (values) {
    //     this.form.reviewAnswers = await values;
    //   }
    // },
    questions: {
      handler() {
        this.setFormValue();
      },
      deep: true,
    },
  },
  mounted() {
    this.setFormValue();
  },
});
