
import { defineComponent } from 'vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import SupportFilesModal from '@/components/modals/forms/AddSupportFiles.vue';
import timeUtil from '@/utils/time';
import { bytesToSize } from '@/utils/file';
import { Actions, Getters } from '@/store/enums/TenantReviewEnums';
import { mapActions, mapGetters } from 'vuex';
import { capitalize } from '@/utils/text';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import _ from 'lodash';
import toasts from '@/utils/toasts';
import useBreakpoints from 'vue-next-breakpoints';
import text from '@/utils/text';

export default defineComponent({
  name: 'support-document-tab',
  components: {
    SupportFilesModal,
    DataTable
  },
  computed: {
    ...mapGetters({
      currentTenantReview: Getters.GET_CURRENT_TENANT_REVIEW,
      actionErrors: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      supportDocuments: Getters.GET_TENANT_REVIEW_SUPPORT_DOCUMENTS,
      authUser: AuthGetters.GET_AUTH_USER,
    }),
    supportDocumentMax() {
      return this.maxDocuments - this.supportDocuments.length;
    },
    canAddSupportFiles() {
      const moveOutDate = new Date(this.currentTenantReview?.move_out_date);
      const added2Months = new Date(
        moveOutDate.setMonth(moveOutDate.getMonth() + 2)
      );
      const currentDate = new Date();

      return currentDate < added2Months;
    },
    isAccessibleByUser() {
      return (this.currentTenantReview?.user.business_id == this.authUser?.business_id);
    },
    isDeletable() {
      const moveOutDate = new Date(this.currentTenantReview?.move_out_date);
      const added2Months = new Date(
        moveOutDate.setMonth(moveOutDate.getMonth() + 2)
      );
      const currentDate = new Date();

      return currentDate < added2Months;
    },
    breakpoint(){
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders(){
      return [
        { name: 'File', key: 'file' },
        { name: 'Size', key: 'size', hidden: this.isMobile },
        { name: 'Date Uploaded', key: 'date', hidden: this.isMobile },
        { name: '', key: 'action', sortable: false, align: 'right'},
      ]
    },
    formattedSupportFiles(){
      return this.supportDocuments.map(item => {
        item.date = this.renderUploadDate(item.created_at);
        item.size = text.formatFileSize(item.size);

        return item;
      })
    }
  },
  data: () => ({
    showUploadModal: false,
    loadingDelete: false,
    loadTable: false,
    currentDeleteId: '',
    maxDocuments: 5,
    debounceHandleFetchSupportDocuments: Function() as any,
    table: {
      currentPage: 1,
      totalPages: 0,
      perPage: 15,
      ordering: false,
      items: [],
    },
  }),
  methods: {
    ...mapActions({
      fetchSupportDocuments: Actions.FETCH_TENANT_REVIEW_SUPPORT_DOCUMENTS,
      deleteSupportFile: Actions.DELETE_SUPPORT_DOCUMENT,
    }),
    addSupportFiles() {
      this.showUploadModal = true;
    },
    bounceFetchDocuments() {
      setTimeout(() => {
        this.handleFetchSupportDocuments();
      }, 2000);
    },
    async handleFetchSupportDocuments() {
      this.loadTable = true;
      const payload = {
        refNo: await this.currentTenantReview.reference_no,
      };

      await this.fetchSupportDocuments(payload);
      this.loadTable = false;
    },
    rendeTitle(title) {
      return capitalize(title);
    },
    renderSize(size) {
      return bytesToSize(size);
    },
    renderUploadDate(date) {
      return timeUtil.dateFormat(date, 'aus');
    },
    async handleDelete(document) {
      if (this.loadingDelete) return;
      this.loadingDelete = true;
      this.currentDeleteId = document.id;

      const payload = {
        refNo: document.key,
        filename: document.filename,
      };

      await this.deleteSupportFile(payload)
        .then(() => {
          this.loadingDelete = false;
          this.currentDeleteId = '';

          toasts.success('File successfully deleted');
        })
        .catch(() => {
          this.loadingDelete = false;
          this.currentDeleteId = '';
          console.log('actionErrors', this.actionErrors);
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });

      this.bounceFetchDocuments();
    },
    async confirmDeleteFile(doc) {
      toasts.confirm(
        'Are you sure you want to delete document?',
        ({ isConfirmed }) => {
          if (isConfirmed) {
            this.handleDelete(doc);
          }
        }
      );
    },
    handleFilesUploaded() {
      this.bounceFetchDocuments();
      this.showUploadModal = false;
    },
  },
});
